html {
	font-size: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
	sans-serif;
	font-size: 1rem;
	line-height: 1.5;
}

div.hidden {
	display: none;
}

div.container-fluid.top-level-container {
	height: 100%;
	padding: 2em;
	display: flex;
	flex-direction: column;
}

button:disabled {
	opacity: 0.5;
}

div.left-half-screen {
	border-bottom: 1em solid black;
	border-right: .5em solid black;
}

div.right-half-screen {
	border-bottom: 1em solid black;
	border-left: .5em solid black;
}

div.row.coin-row {

}

div.card.slot-card {

}

div.selection-box {
	border: .5em solid;
	border-radius: .5em;
}

/*
Unselected still has a border to prevent odd realignments when selection happens.
The border is just transparent.
*/
div.selection-box.unselected {
	border-color: rgba(100, 100, 100, 0);
}

div.selection-box.selected {
	border-color: cornflowerblue;
}

div.selection-box.selected.right-side {
	border-left: none;
}

div.selection-box.selected.left-side {
	border-right: none;
}

div.card.coin-card {
	align-items: center;
}

img.coin-image {
	height: 5rem;
	width: 5rem;
}
